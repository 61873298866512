import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setObjPicture } from '../../features/pictureSlice';
import { useUserContext } from '../../user-provider';
import { LOCAL_CONSTANT } from "../Const";
import PhotoEdit from '../subPage/Pictuer/PhotoEdit';
import { apiRequest } from "../../api/ApiCall";
import { userLogging } from "../../utility/firestore";
import { useUtilityContext } from '../../utility-provider';
import {
    setPhoto,
    setViewingDistanceParam,
} from '../subPage/Pictuer/PhotoFunction';
import { setSaveStatus } from '../../features/longPageSlice';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
    Box,
    Button,
    Container,
    Grid,
    InputLabel,
    Paper,
    Typography,
    styled,
} from '@mui/material';
import { getFileData, getFileMetadata, uploadFile } from '../../utility/storage';
import InputWithIcons from '../components/InputWithIcons';
import PhotoSelectModal from '../subPage/Pictuer/PhotoSelectModal';
import { DeleteConfirmationModal } from '../ResearchReport/DeleteConfirmationModal';
import { setPermissionFunc } from '../components/PermissionFunc';
import {
    PictureBooleanTypeList,
    PictureStringTypeList,
    initPictureCommentTextFieldObj,
    initPictureReadOnlyObj,
    initPictureShowEditIconObj,
    initPictureShowIconObj
} from '../../data/type';
import { getAccessToken } from '../Common';
import DeficiencyCommentRegistModal from '../components/DeficiencyCommentRegistModal';
import {
    chatBubbleCircleSx,
    chatBubbleIconSx
} from '../components/ChatBubbleAndEditIconStyle';

export interface resultDialogProps {
    props: {
    }
}
const REPLACESTRING = '_ID_'
const DEFAULTPHOTO = '/image/picture/defaultPic' + REPLACESTRING + '.png'
const DEFAULTPHOTMETA = { 'name': 'defaultPic' + REPLACESTRING + '.png', 'contentType': 'image/png' }
const replaceDefaultPhoto = (id: number) => {
    return DEFAULTPHOTO.replace(REPLACESTRING, id.toString()).toString()
}
const replaceDefaultPhotMeta = (id: number) => {
    return { 'name': DEFAULTPHOTMETA['name'].replace(REPLACESTRING, id.toString()), 'contentType': 'image/png' }
}
//凡例画像表示箇所オブジェクト初期値
const picListItem = () => {
    return [
        { 'id': 1, 'disabled': true, 'title': '前面道正方向手前、反対車線から', 'fullPath': replaceDefaultPhoto(1), 'url': replaceDefaultPhoto(1), 'discription': '※敷地接線に赤線をおく', 'downloadPath': '', 'meta': replaceDefaultPhotMeta(1), 'columnName': 'pictuer_1_path' },
        { 'id': 2, 'disabled': true, 'title': '側道Ａ正方向手前、反対車線から', 'fullPath': replaceDefaultPhoto(2), 'url': replaceDefaultPhoto(2), 'discription': '※敷地接線に赤線をおく', 'downloadPath': '', 'meta': replaceDefaultPhotMeta(2), 'columnName': 'pictuer_2_path' },
        { 'id': 3, 'disabled': true, 'title': '側道Ｂ正方向手前、反対車線から', 'fullPath': replaceDefaultPhoto(3), 'url': replaceDefaultPhoto(3), 'discription': '※敷地接線に赤線をおく', 'downloadPath': '', 'meta': replaceDefaultPhotMeta(3), 'columnName': 'pictuer_3_path' },
        { 'id': 4, 'disabled': true, 'title': '裏道正方向手前、反対車線から', 'fullPath': replaceDefaultPhoto(4), 'url': replaceDefaultPhoto(4), 'discription': '※敷地接線に赤線をおく', 'downloadPath': '', 'meta': replaceDefaultPhotMeta(4), 'columnName': 'pictuer_4_path' },
        { 'id': 5, 'disabled': true, 'title': '中央線、中央分離帯の状況', 'fullPath': replaceDefaultPhoto(5), 'url': replaceDefaultPhoto(5), 'discription': '※前面道正方向手前、正車線から撮影', 'downloadPath': '', 'meta': replaceDefaultPhotMeta(5), 'columnName': 'pictuer_5_path' },
        { 'id': 6, 'disabled': true, 'title': '歩道、ガードレールの状況', 'fullPath': replaceDefaultPhoto(6), 'url': replaceDefaultPhoto(6), 'discription': '※前面道路正方向、敷地１０ｍ手前から撮影。（歩道、植栽、ガードレール等高さがわかる画像）', 'downloadPath': '', 'meta': replaceDefaultPhotMeta(6), 'columnName': 'pictuer_6_path' },
        { 'id': 7, 'disabled': true, 'title': '新店頭看板が見える位置から（看板予定位置に置いてください）', 'fullPath': replaceDefaultPhoto(7), 'url': replaceDefaultPhoto(7), 'discription': '※寄付きの建物視界性の距離から撮影。　店頭看板予定位置に【７】のマークをおく。', 'downloadPath': '', 'meta': replaceDefaultPhotMeta(7), 'columnName': 'pictuer_7_path' },
        { 'id': 8, 'disabled': true, 'title': 'サインポールの見える位置から（サインポール予定位置に置いてください）', 'fullPath': replaceDefaultPhoto(8), 'url': replaceDefaultPhoto(8), 'discription': '※サインポールが最後に見える位置から撮影。　サインポール予定位置に【ＳＰ】のマークをおく。', 'downloadPath': '', 'meta': replaceDefaultPhotMeta(8), 'columnName': 'pictuer_8_path' },
    ]
}

//画像格納オブジェクト初期値
const initProfileImage = () => {
    return [
        { 'id': 1, 'url': '' },
        { 'id': 2, 'url': '' },
        { 'id': 3, 'url': '' },
        { 'id': 4, 'url': '' }
    ]
};

//styledでimgタグをリサイズ化
const ResizedImage = styled('img')({
    maxWidth: '558px',
    maxHeight: '358px'
});

//アスタリスク
const RedAsterisk = styled('span')({
    color: 'red',
    marginLeft: '4px',
    display: 'flex',
    alignItems: 'center',
});


const Picture: React.FC<resultDialogProps> = ({ props }) => {
    const utilityCtx = useUtilityContext();

    const [open, setOpen] = React.useState<boolean>(false);
    const [editOpen, setEditOpen] = React.useState<boolean>(false);

    //押下されたボタンの箇所
    const [buttonNum, setButtonNum] = useState(0);

    //inputファイルのref属性
    const inputRef = useRef<HTMLInputElement>(null);

    //画像格納オブジェクトの宣言
    const [profileImage, setProfileImage] = useState(initProfileImage);

    //画像編集画面遷移フラグ
    const [editFlg, setEditFlg] = useState(false);

    //編集するアップロードした画像
    const [editImgSrc, setEditImgSrc] = useState("");
    const [editImgMeta, setEditImgMeta] = useState({ 'name': 'pic1.png' });

    //写真リスト
    const [photoArray, setPhotoArray] = useState(picListItem);
    const [changePhoto, setChangePhoto] = useState(false);

    //第一進入口の左端から、最後に見える位置までの距離
    const [viewingDistanceObj, setViewingDistanceObj] = useState<{ viewing_distance: number }>({ viewing_distance: 0 });

    useEffect(() => {
        console.log("現在の編集フラグは" + editFlg)
    }, [])

    //パラメータオブジェクト
    const objLongPageSaveStatus = useAppSelector((state) => state.longPage.saveStatus);

    const objPicture = useAppSelector((state) => state.picture);

    //ロングページオブジェクト 候補地NOと枝を取得
    const objLongPageParam = useAppSelector((state) => state.longPage.param);
    //ステータス管理sequenceとタスクIDを取得するため
    const objLocationBase = useAppSelector((state) => state.longPage.locationBase);

    //モーダル開閉フラグ(削除確認)
    const [deleteConfModalOpen, setDeleteConfModalOpen] = useState(false);
    const [photoArrayId, setPhotoArrayId] = useState<number>(0);


    //不備コメントモーダル項目名
    const [columnNameStr, setColumnNameStr] = useState<string>("");
    //不備コメントモーダルラベル名
    const [labelNameStr, setLabelNameStr] = useState<string>("");
    //不備コメントモーダル開閉フラグ
    const [deficiencyComentModalFlag, setDeficiencyComentModalFlag] = useState(false);
    //アイコン全体の表示非表示のステータス
    const [pictureShowIconsObj, setPictureShowIconsObj] = useState<PictureBooleanTypeList>(initPictureShowIconObj);
    //不備コメントモーダル内、TextFieldのコメント
    const [pictureCommentTextFieldObj, setPictureCommentTextFieldObj] = useState<PictureStringTypeList>(initPictureCommentTextFieldObj);
    //項目値を編集可能にするステータスの型定義
    const [pictureReadOnlyObj, setPictureReadOnlyObj] = useState<PictureBooleanTypeList>(initPictureReadOnlyObj);

    const handleDeleteConfModalOpen = (id: number) => {
        const urlString = getViewUrl(id);
        if (!urlString) {
            return
        }
        setDeleteConfModalOpen(true);
        setPhotoArrayId(id);
    }

    //表示するUrlをオブジェクトから取得する関数
    const getViewUrl = (viewId: number) => {
        const selectItem = photoArray.find(
            item => item.id === viewId
        );
        return selectItem ? selectItem.url : "";
    };

    //表示するオブジェクトのmetaを取得する関数
    const getViewMeta = (viewId: number) => {
        const selectItem = photoArray.find(
            item => item.id === viewId
        );

        return selectItem ? selectItem.meta : replaceDefaultPhotMeta(viewId)
    };

    //[input type=file]をuseRef()で発火させた際のイベント
    const onFileInputChange = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {

        if (e.target && e.target.files) {

            //React.ChangeEvent<HTMLInputElement>より選択した画像ファイルを取得
            const files = e.target.files;

            //取得した画像データをオブジェクトとして格納
            const fileObject = files[0];

            //createObjectURLで選択した画像のURLを取得して代入
            const urlObj = window.URL.createObjectURL(fileObject);

            //取得した表示箇所の番号に選択されたURLを格納
            const newProfileImage = [...profileImage];
            newProfileImage[id - 1] = { ...newProfileImage[id - 1], url: urlObj };

            //useState()を更新してオブジェクトへ画像のURLを格納
            setProfileImage(newProfileImage);

            //次回同じ画像ファイルを選んでもイベント発火するように初期化
            e.target.value = "";

        } else {
            return;
        }
    };

    //写真アップロードボタン押下時のイベント
    const fileUpload = (id: number) => {
        //押下したボタンの位置をuseState()で更新
        setButtonNum(id);

        setOpen(true)
    };

    //画像編集ボタン押下時のイベント
    const changeEdit = (editId: number) => {
        //編集用画像のステート更新
        setEditImgSrc(getViewUrl(editId));
        setEditImgMeta(getViewMeta(editId));
        setButtonNum(editId);
        setEditFlg(true);
        setEditOpen(true);
    };

    //キャンセルボタン押下時のイベント
    const cancelPhotoSelect = (editId: number) => {
        //取得した表示箇所の番号に格納されたURL項目を初期化
        const newProfileImage = [...profileImage];
        newProfileImage[editId - 1] = { ...newProfileImage[editId - 1], url: "" };

        //useState()を更新
        setProfileImage(newProfileImage);
    };

    // 写真の差し替え
    const handleChangePhoto = (id: number, item: any) => {
        photoArray.map((value: any) => {
            if (value.id === id) {
                value.url = item.imgPath
                value.downloadPath = item.fullPath
                value.meta = item.metaData
                value.disabled = false
                value.fullPath = '/' + item.fullPath
                upsertPhoto(id, item.fullPath, 'upsert')
            }
        });
        (async () => {
            try {
                await uploadFile(item, objLongPageParam.planAreaId + '/' + objLongPageParam.branch + '/photo/' + item.name);
            }catch (e) {
                console.log(e);
            }
        })();
    }

    // 編集写真の差し替え
    const changeEditPhoto = (id: number, path: string) => {
        photoArray.map((value: any) => {
            if (value.id === id) {
                getFileData(path)
                    .then((imageData: any) => {
                        const img = new Image()
                        img.src = imageData
                        value.url = img.src
                        value.fullPath = path
                        value.downloadPath = imageData
                        value.disabled = false
                    }).catch((e) => {
                        setChangePhoto(!changePhoto)
                    })
                getFileMetadata(path)
                    .then((metaData: any) => {
                        value.meta = metaData
                        setEditFlg(false)
                        setChangePhoto(!changePhoto)
                        upsertPhoto(id, path, 'edit-upsert')
                    }).catch((e) => {
                        setChangePhoto(!changePhoto)
                    })
            }
        })
    }

    const dispatchPicture = (id: number, photoPath: string) => {
        const path = 'pictuer_%ID%_path'
        const labelName = path.replace('%ID%', id.toString())
        dispatch(setObjPicture({ ...objPicture, [labelName]: photoPath }));
    }


    const upsertPhoto = (id: number, path: string, mode: string) => {
        let upsert_path = ""
        if (mode.includes('edit-upsert')) {
            upsert_path = path.replace('/' + objPicture.plan_area_id + '/' + objPicture.branch, "")
            dispatchPicture(id, upsert_path);
        } else if (mode.includes('upsert')) {
            upsert_path = path.replace(objPicture.plan_area_id, "")
            dispatchPicture(id, upsert_path);
        }
        const params = {
            mode: "regist",
            endPoint: "/location/Picture/v1/regist",
            query: {
                plan_area_id: objPicture.plan_area_id,
                branch: objPicture.branch,
                pictuer_1_path: setUpsertPath(1, id, upsert_path, mode),
                pictuer_2_path: setUpsertPath(2, id, upsert_path, mode),
                pictuer_3_path: setUpsertPath(3, id, upsert_path, mode),
                pictuer_4_path: setUpsertPath(4, id, upsert_path, mode),
                pictuer_5_path: setUpsertPath(5, id, upsert_path, mode),
                pictuer_6_path: setUpsertPath(6, id, upsert_path, mode),
                pictuer_7_path: setUpsertPath(7, id, upsert_path, mode),
                pictuer_8_path: setUpsertPath(8, id, upsert_path, mode),
                viewing_distance: objPicture.viewing_distance,
                upload_image: uploadImageWord(mode, id),
            }
        };
        updateRegist(params)
    }

    const setUpsertPath = (pathId: number, id: number, upsertPath: string, mode: string) =>{
        if(pathId === id && (mode === 'upsert' || mode === 'edit-upsert')){
            return upsertPath;
        } else if(pathId !== id && !photoArray[pathId-1]['fullPath'].includes("defaultPic")){
            const filePath = photoArray[pathId-1]['fullPath'];
            return filePath.substring(filePath.indexOf("/photo"));
        } else {
            return "";
        }
    }

    const uploadImageWord = (mode: string, id: number) => {
        let modeValue = "現況写真登録"
        if (mode === 'edit-upsert') {
            modeValue = "現況写真編集"
        } else if (mode === 'delete') {
            modeValue = "現況写真削除"
        }
        const wordList = [
            "前面道",
            "側A",
            "側B",
            "裏道",
            "中央線/分離帯",
            "歩道/ガードレール",
            "新店頭看板",
            "サインポール"
        ]

        return modeValue + "<br>" + wordList[id - 1]
    }

    const updateRegist = (params: any) => {
        userLogging(
            LOCAL_CONSTANT.FUNCTION_NAME.LOCATION_PICTURE_UPDATE,
            params,
            user
        );
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }
        (async () => {
            try {
                await apiRequest(params);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            } catch (e) {
                console.log(e);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            }
        })();
    }

    // 写真を初期値に戻す
    const deletePhoto = (id: number) => {
        photoArray.map((value: any) => {
            if (value.id === photoArrayId) {
                value.url = replaceDefaultPhoto(photoArrayId)
                value.downloadPath = ''
                value.meta = replaceDefaultPhotMeta(photoArrayId)
                value.disabled = true
                value.fullPath = replaceDefaultPhoto(photoArrayId)
                dispatchPicture(photoArrayId, "")
                upsertPhoto(photoArrayId, value.fullPath, 'delete')
            }
        })
        setChangePhoto(!changePhoto)
        setPhotoArray(photoArray)
    }

    const getDate = (item: any) => {
        if (
            item.meta !== undefined &&
            item.meta.customMetadata !== undefined &&
            item.meta.customMetadata.GPSDateTimeStamp !== '' &&
            item.meta.customMetadata.GPSDateTimeStamp !== null
        ) {
            return item.meta.customMetadata.GPSDateTimeStamp
        }
        return 'YYYY/MM/DD HH:MM:SS'
    }

    React.useEffect(() => {
        if (objPicture) {
            setPhotoArray(setPhoto(objPicture, photoArray))
            setViewingDistanceObj(setViewingDistanceParam(objPicture));
        }
    }, [objPicture])

    const dispatch = useAppDispatch();

    //Redux インプット
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, labelName: string) => {
        const targetValue = e.target.value;
        //空文字または0～150まで入力可能
        if (/^$|^(0|[1-9][0-9]?|1[0-4][0-9]|150)$/.test(targetValue)) {
            setViewingDistanceObj({ ...viewingDistanceObj, [labelName]: targetValue });
        }
        // 更新フラグ
        if (!objLongPageSaveStatus.Picture) dispatch(setSaveStatus({ ...objLongPageSaveStatus, Picture: true }));
    };

    //入力不可のインプットをクリックしたときアイコンを表示
    const handleInputClick = (labelName: string) => {
        setPictureShowIconsObj({ ...pictureShowIconsObj, [labelName]: true });
    };

    //吹き出しをクリックしたとき、モーダル画面を開く
    const handleChatBubbleClick = (labelName: string) => {
        setLabelNameStr(labelName);
        setDeficiencyComentModalFlag(true);
        setPictureCommentTextFieldObj({ ...pictureCommentTextFieldObj, [labelName]: "" }); //テキストフィールドのコメントを初期化
    };

    //不備コメントモーダル内でのコメント取得
    const handleCommentChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, labelName: string) => {
        setPictureCommentTextFieldObj({
            ...pictureCommentTextFieldObj,
            [labelName]: e.target.value,
        });
    };

    //不備コメントモーダル内の保存ボタンをクリックしたときコメントを保存
    const handleSaveComment = (labelName: string) => {
        //不備コメントを登録
        const params = {
            mode: "regist",
            endPoint: "/location/Deficiency/v1/regist",
            query: {
                plan_area_id: objLongPageParam.planAreaId,
                branch: objLongPageParam.branch,
                status_seq: objLocationBase.status_seq,
                table_name: "location_pictuer",
                column_name: labelName,
                comment: pictureCommentTextFieldObj[labelName],
            }
        };
        userLogging(
            LOCAL_CONSTANT.FUNCTION_NAME.LOCATION_PICTURE_COMMENT,
            params,
            user
        );
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }
        (async () => {
            try {
                await apiRequest(params);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
                //不備コメントモーダルを閉じる
                setDeficiencyComentModalFlag(false);
                //アイコンを非表示
                setPictureShowIconsObj({ ...pictureShowIconsObj, [labelName]: false });
            } catch (e) {
                console.log(e);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
                //不備コメントモーダルを閉じる
                setDeficiencyComentModalFlag(false);
                //アイコンを非表示
                setPictureShowIconsObj({ ...pictureShowIconsObj, [labelName]: false });
            }
        })();
    };

    // インプットからフォーカスがずれたら、インプット編集不可とアイコンを非表示にする
    const handleInputBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>, labelName: string) => {
        //Reduxへ保存
        dispatch(setObjPicture({ ...objPicture, [labelName]: e.target.value }));

        // インプット編集不可
        setPictureReadOnlyObj({ ...pictureReadOnlyObj, [labelName]: true });

        //インプットからフォーカスが移動する先がnullの場合アイコンを閉じる
        if (!(e.relatedTarget)) {
            setPictureShowIconsObj({ ...pictureShowIconsObj, [labelName]: false });
        }

        //インプットからフォーカスが移動する先がインプットタグの場合アイコンを閉じる
        if (e.relatedTarget && e.relatedTarget.tagName === "INPUT") {
            setPictureShowIconsObj({ ...pictureShowIconsObj, [labelName]: false });
        }

    };

    const [userType, setUserType] = useState('');
    const user = useUserContext();

    //ユーザー取得タイミングによる画面制御
    useEffect(() => {
        if (JSON.parse(user.userData || 'null') !== null) {
            setUserType(JSON.parse(user.userData || 'null').user_type);
        }
    }, [user]);

    const setPhotoUrl = (url: any) => {
        let path = ""
        const bucket_path = process.env.REACT_APP_GOOGLE_STORAGE + '/' + process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
        const access_token = getAccessToken()
        if (url.fullPath.startsWith('/image/picture')) {
            path = url.fullPath
        } else {
            if (url.fullPath.includes('/photo/IMG_')) {
                path = bucket_path + '/' + objLongPageParam.planAreaId
            } else if (url.fullPath.includes('/photo/EDITIMG_')) {
                path = bucket_path + '/' + objLongPageParam.planAreaId + '/' + objLongPageParam.branch
            }
            if (url.fullPath.includes(objLongPageParam.planAreaId)) {
                path = bucket_path
            }
            path += url.fullPath + access_token
        }

        return path
    }

    const photoButtonDisable = (url: any, key: string) => {
        let disabled = true
        if (!url.fullPath.startsWith('/image/picture')) {
            disabled = setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.PICTURE, key)
        }

        return disabled
    }

    return (
        <>
            <Box sx={{ width: "100%" }}>
                <Box>
                    <Container sx={{ margin: "50px 50px 0px 50px" }}>
                        <Box>
                            <InputLabel>{LOCAL_CONSTANT.LABEL.PHOTO_REGISTRATION}</InputLabel>
                        </Box>
                        <Grid container spacing={2}>
                            {photoArray.map((value: any, index: any) => (
                                <Grid item xs={6} key={index}>
                                    <Box>
                                        {/* 凡例画像表示箇所 */}
                                        <Paper style={{ padding: 5 }}>
                                            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                                <Box sx={{ padding: '10px 0' }}>{value.id + ' ' + value.title}</Box>
                                                <Box sx={{ display: 'flex' }}>
                                                    {userType === 'z001' &&
                                                        <Button
                                                            disabled={setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.PICTURE, 'photoCommentButton')}
                                                            onClick={() => { handleChatBubbleClick(value.columnName) }}
                                                        >
                                                            <Box sx={chatBubbleCircleSx(setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.PICTURE, 'photoCommentButton'))}>
                                                                <ChatBubbleIcon sx={chatBubbleIconSx} />
                                                            </Box>
                                                        </Button>
                                                    }
                                                    <Button
                                                        sx={{ color: 'black' }}
                                                        disabled={setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.PICTURE, 'photoAddButton')}
                                                        onClick={() => fileUpload(value.id)}
                                                    >
                                                        <AddAPhotoIcon fontSize="large" />
                                                    </Button>
                                                    <Button
                                                        sx={{ color: 'black' }}
                                                        disabled={photoButtonDisable(value, 'photoEditButton')}
                                                        onClick={() => changeEdit(value.id)}
                                                    >
                                                        <EditIcon fontSize="large" />
                                                    </Button>
                                                    <Button
                                                        sx={{ color: 'black' }}
                                                        disabled={photoButtonDisable(value, 'photoDeleteButton')}
                                                        onClick={() => handleDeleteConfModalOpen(value.id)}
                                                    >
                                                        <DeleteIcon fontSize="large" />
                                                    </Button>
                                                </Box>
                                            </Box>
                                            <Box sx={{ width: '558px', height: '358px', textAlign: 'center' }}><ResizedImage src={setPhotoUrl(value)} /></Box>
                                            <InputLabel>{LOCAL_CONSTANT.LABEL.SHOOTING_DATE_AND_TIME + LOCAL_CONSTANT.LABEL.FULL_WIDTH_SPACE}{getDate(value)}</InputLabel>
                                            <Box>{value.discription}</Box>
                                        </Paper>
                                        <PhotoSelectModal key={value.id} props={{ open: open, handleModal: setOpen, keyId: buttonNum, rowData: value, changePhoto: handleChangePhoto }} />
                                        {/* アップロード画像編集画面 */}
                                        <PhotoEdit
                                            key="edit"
                                            props={{
                                                open: editOpen, handleModal: setEditOpen,
                                                editImgSrc: editImgSrc,
                                                editImgMeta: editImgMeta,
                                                editFlg: editFlg,
                                                setEditFlg: setEditFlg,
                                                rowData: value,
                                                openId: buttonNum,
                                                changeEditPhoto: changeEditPhoto,
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Container>
                    <Container sx={{ margin: "10px 50px 0px 50px" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}></Grid>
                            <Grid item xs={4}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography>第一進入口の左端から、最後に見える位置までの距離（最大150m）</Typography>
                                    <RedAsterisk>＊</RedAsterisk>
                                </Box>
                            </Grid>
                            <Grid item xs={2}>
                                <InputWithIcons props={{
                                    userType: userType, //ユーザー
                                    disabled: setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.PICTURE, 'viewing_distance'), //ユーザー権限
                                    pageName: LOCAL_CONSTANT.NAVI.PICTURE,
                                    labelName: 'viewing_distance',
                                    startAdornment: '',
                                    endAdornment: 'm',
                                    InputValueObj: viewingDistanceObj,
                                    ReadOnlyObj: pictureReadOnlyObj,
                                    setReadOnlyObj: setPictureReadOnlyObj,
                                    ShowEditIconObj: initPictureShowEditIconObj,
                                    ShowIconsObj: pictureShowIconsObj,
                                    handleChatBubbleClick: handleChatBubbleClick,
                                    handleInputBlur: handleInputBlur,
                                    handleInputChange: handleInputChange,
                                    handleInputClick: handleInputClick
                                }} />
                            </Grid>
                        </Grid>
                    </Container>
                </Box>

                {/* 削除確認モーダル */}
                <DeleteConfirmationModal modalOpen={deleteConfModalOpen} setModalOpen={setDeleteConfModalOpen} deleteMethod={deletePhoto} />

                {/*不備コメント モーダル画面 */}
                <DeficiencyCommentRegistModal
                    props={{
                        modalOpen: deficiencyComentModalFlag,
                        setModalOpen: setDeficiencyComentModalFlag,
                        CommentTextFieldObj: pictureCommentTextFieldObj,
                        labelName: labelNameStr,
                        handleCommentChange: handleCommentChange,
                        handleSaveComment: handleSaveComment,
                    }} />
            </Box>
        </>
    );
}


export default Picture;
